import LoadingIcon from '@/assets/loading.svg?react';
import { signInRedwoodUserWithOkta } from '@/auth/sign-in-with-sso';
import { useUser } from '@/auth/user-store';
import { Button } from '@/components/button';
import { Card } from '@/components/card';
import { RedwoodOktaSignInForm } from '@/components/login-form';
import { pageContentClassName } from '@/utils/class-names';

type AuthenticatedPageProps = {
  children: React.ReactNode;
};

export default function AuthenticatedPage({ children }: AuthenticatedPageProps) {
  const { isSignedIn, signOut, isLoading, userType } = useUser();

  // TODO -> add more granular permissions check once we figure out a way to translate
  // user groups into either user types or a permission list or something
  const hasRequiredPermissions = userType === 'redwood';

  return (
    <>
      {import.meta.env.SSR || isLoading ? (
        // Show loading icon until client has loaded
        <main className="flex grow items-center justify-center">
          <LoadingIcon className="block h-24 w-24" />
        </main>
      ) : !isSignedIn ? (
        // Prompt login if not logged-in
        <main>
          <div className="mx-auto my-6 flex max-w-sm flex-col items-center gap-6 md:mt-16">
            <RedwoodOktaSignInForm handleSignIn={() => signInRedwoodUserWithOkta()} />
          </div>
        </main>
      ) : (
        <main className={pageContentClassName}>
          {hasRequiredPermissions ? (
            // Show authenticated content if user has required permissions
            children
          ) : (
            <Card heading={'Access Denied'}>
              <p className="mb-3 mt-2">
                Sorry, you don't have permission to access this page. Please sign-out and sign back
                in with a different account.
              </p>
              <Button variant="green" onClick={() => signOut()}>
                Sign Out
              </Button>
            </Card>
          )}
        </main>
      )}
    </>
  );
}
