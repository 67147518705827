import { Button } from '@/components/button';
import { Card } from '@/components/card';
import { useState } from 'react';

// "Sign in with Okta" card for Redwood Materials employees
export function RedwoodOktaSignInForm({ handleSignIn }: { handleSignIn: () => Promise<void> }) {
  const [isRedirecting, setRedirecting] = useState(false);
  const onSignInClick = () => {
    setRedirecting(true);
    handleSignIn().catch(() => setRedirecting(false));
  };
  return (
    <>
      <Card
        className={'flex flex-col items-center gap-2 text-center'}
        heading="Redwood Employee Sign-in"
      >
        <p className="mb-1">
          Please sign-in with your Redwood Materials account to view this page:
        </p>
        <Button variant="purple" onClick={onSignInClick}>
          {isRedirecting ? 'Signing in with Okta...' : 'Sign in with Okta'}
        </Button>
      </Card>
    </>
  );
}
