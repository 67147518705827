import { setRedirectRoutes } from '@/auth/redirect-user-after-oauth-callback';
import { signInWithRedirect } from 'aws-amplify/auth';

export function signInRedwoodUserWithOkta() {
  // Bring user back to this page after signing in
  setRedirectRoutes(location.pathname);

  // Redirect user to Okta
  return signInWithRedirect({
    provider: { custom: import.meta.env.VITE_OKTA_IDENTITY_PROVIDER_NAME },
  });
}
